import styled from "@emotion/styled"
import { Colors, HTMLSelect } from "@blueprintjs/core"

export const FilterSelect = styled(HTMLSelect)`
  select {
    font-size: 13px;
    padding-left: 6px;
  }
`

export const RenderedPreviewWrapper = styled.div`
  box-shadow: 0px 2px 8px rgba(92, 112, 128, 0.5);
  background-color: white;
  border: 1px solid #738694;
  border-radius: 2px;
`

export const RenderedPreview = styled.img<{
  height?: string
  width?: string
  maxHeight?: number
}>`
  position: absolute;
  display: block;
  height: ${(props) => props.height ?? "714px"};
  width: ${(props) => props.width ?? "552px"};
  max-height: ${(props) => `${props.maxHeight}px` || "auto"};
`

export const InsertContainer = styled.div`
  text-align: center;
  padding: 1rem 0;
`

export const MainDetail = styled.div`
  grid-column: span 2;
  height: calc(100vh - 48px); // TEMP
  display: flex;
  flex-direction: column;
`

export const Main = styled.div<{
  isDragging: boolean
  isDroppable: boolean
  noPadding?: boolean
}>`
  padding: ${(props) => (props.noPadding ? "0" : "24px 49px")};
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  position: relative;

  &:after {
    content: "";
    display: ${(props) =>
      props.isDragging && props.isDroppable ? "block;" : "none;"};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    /* Colors.VIOLET4 at 70% transparency */
    background: rgba(189, 107, 189, 0.3);
    border-radius: 5px;
    margin: 0 auto;
    z-index: 1;
  }
`

export const DroppableMessage = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: 24px;
  z-index: 10;
  background: ${Colors.VIOLET3};
  color: ${Colors.WHITE};
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  width: 403px;
  height: 71px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;

  .bp4-icon {
    margin-right: 22px;
  }
`

export const PreviewDetail = styled.div`
  position: relative;
`

export const PreviewDetailContainer = styled.div<{ top?: number }>`
  transform: translateY(-${(props) => props.top || 0}px);
  transition: transform 0.25s ease-in-out;
`

export const PreviewPage = styled.div`
  position: relative;
`

export const PreviewMeta = styled.div<{
  isZooming?: boolean
}>`
  ${(props) => !props.isZooming && "height: 100%;"}
  position: absolute;
  top: 0;
  ${(props) => !props.isZooming && "bottom: 0;"}
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
`

export const PreviewSelectSignaturePage = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 1.5rem;
`

export const PageContent = styled.div<{
  reverse?: boolean
}>`
  padding-left: 1rem;
  min-width: 360px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  align-content: flex-start;
  overflow-y: auto;
  height: 100%;

  @media (min-width: 1536px) {
    ${(props) =>
      props.reverse &&
      `
    flex-wrap: wrap-reverse;
    `}
  }
`

export const Column = styled.div`
  width: 360px;
  min-width: 360px;
  padding: 0 0.25rem 0 0.25rem; // To account for focus rings on inputs
`

export const Spacer = styled.div`
  width: 3rem;
  min-width: 3rem;
  flex: 0;
`

export const ThumbnailColumn = styled.div<{ hasPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  overflow: hidden;
  padding: ${(props) => (props.hasPadding ? "24px 0 24px 49px" : "0")};
`

export const ThumbnailContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0.25rem;
  margin-top: 0.5rem;
`

export const ThumbnailPreview = styled.div<{ isSelected?: boolean }>`
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  margin-bottom: 0.75rem;
  padding: 2px;
  position: relative;
  ${(props) =>
    props.isSelected &&
    `
    border: 2px solid #5a829e;
    border-radius: 3px;
    box-shadow: 0 0 0 4px rgba(90, 130, 158, 0.4);
  `}
  &:hover {
    border: 2px solid #5a829e;
    border-radius: 3px;
    box-shadow: 0 0 0 4px rgba(90, 130, 158, 0.4);
  }
`

export const ThumbnailPreviewMeta = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
`

export const ThumbnailPreviewIcon = styled.div<{
  height?: string
  width?: string
  isThumbnail?: boolean
  isSelected?: boolean
}>`
  background: white;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props?.height ?? "24px"};
  width: ${(props) => props?.width ?? "24px"};
  border-radius: 50%;
  box-shadow:
    0px 1px 1px rgba(16, 22, 26, 0.2),
    0px 0px 0px 1px rgba(16, 22, 26, 0.1);
  svg {
    color: ${(props) =>
      props.isThumbnail && props.isSelected
        ? " #5C7080"
        : props.isThumbnail
          ? "#ced9e0"
          : "#5C7080"};
  }
`

export const ThumbnailPreviewIcons = styled.div`
  display: flex;
  padding: 0.5rem;
`

export const ThumbnailPreviewPageNumber = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
`

export const ThumbnailPreviewPageNumberBadge = styled.div`
  background-color: white;
  border-radius: 1rem;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  box-shadow:
    0px 1px 1px rgba(16, 22, 26, 0.2),
    0px 0px 0px 1px rgba(16, 22, 26, 0.1);
  line-height: 1.2;
`
